document.addEventListener('DOMContentLoaded', function() {
  const video = document.querySelector('video');
  const soundBtn = document.querySelector('.sound');
  const fullscreenBtn = document.querySelector('.fullscreen');
  const playBtn = document.querySelector('.play');
  const progressBar = document.querySelector('.progress-bar');
  const bufferBar = document.querySelector('.bufferBar');
  const timeBar = document.querySelector('.timeBar');
  // const controlBar = document.querySelector('.control');

  // Функція відтворення та призупинення відео
  function toggleVideo() {
    const cursorInner = document.getElementById('cursorInner');

    if (!cursorInner) {
      console.error('Element with ID cursorInnerId not found');
      return;
    }

    if (video.paused) {
      video.play();
      cursorInner.innerHTML = '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<rect width="80" height="80" rx="40" fill="white"/>\n' +
        '<rect x="34" y="33" width="4" height="14" fill="black"/>\n' +
        '<rect x="42" y="33" width="4" height="14" fill="black"/>\n' +
        '</svg>\n';
    } else {
      video.pause();
      cursorInner.innerHTML = '      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '        <rect width="80" height="80" rx="40" fill="white"/>\n' +
        '        <path d="M34 47V33L46 40L34 47Z" fill="black"/>\n' +
        '      </svg>\n';
    }
  }

  // Додати обробник події для відтворення відео при кліку на кнопку плей
  if (video) {
    video.addEventListener('click', toggleVideo);
  }
  if (playBtn) {
    playBtn.addEventListener('click', toggleVideo);
  }

  // Клік на кнопці Mute/Unmute sound
  soundBtn.addEventListener('click', function() {
    if (video.muted) {
      video.muted = false;
      soundBtn.innerHTML = '<i class="fa fa-volume-up" aria-hidden="true"></i>';
    } else {
      video.muted = true;
      soundBtn.innerHTML = '<i class="fa fa-volume-off" aria-hidden="true"></i>';
    }
  });

  // Клік на кнопці Fullscreen
  fullscreenBtn.addEventListener('click', function() {
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.webkitRequestFullscreen) {
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      video.msRequestFullscreen();
    }
  });

  // Оновлення прогрес-бару відтворення відео
  video.addEventListener('timeupdate', function() {
    const bufferWidth = (video.buffered.end(0) / video.duration) * 100;
    bufferBar.style.width = bufferWidth + '%';

    const currentTime = (video.currentTime / video.duration) * 100;
    timeBar.style.width = currentTime + '%';
  });

  // Перемотка відео при кліку на прогрес-бар
  progressBar.addEventListener('click', function(e) {
    const rect = progressBar.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const newTime = offsetX / rect.width * video.duration;
    video.currentTime = newTime;
  });
});
