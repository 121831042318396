// Показуємо прелоадер при першому завантаженні сторінки
if (!sessionStorage.getItem('visited')) {
  showLoader();
  document.body.classList.add('loader-circle-show');
} else {
  hideLoader();
}

// Функція показу прелоадера
function showLoader() {
  // Показуємо прелоадер
  document.getElementById('loaderContainer').classList.remove('hidden');
  document.body.style.overflow = 'hidden'; // Заборонити прокрутку під час завантаження

  // Симуляція завантаження
  let progress = 0;
  const interval = setInterval(() => {
    progress += 1; // Інкрементуємо прогрес на 1% кожну секунду
    document.getElementById('loaderText').innerText = `${progress}%`;
    if (progress >= 100) {
      clearInterval(interval); // Зупиняємо інтервал, коли досягнуто 100%
      setTimeout(() => {
        hideLoader();
      }, 1000);
    }
  }, 10); // Запускаємо інтервал кожну 10 мс
}

// Функція приховання прелоадера
function hideLoader() {
  document.getElementById('loaderContainer').classList.add('preloader-hidden'); // Приховуємо прелоадер
  document.body.style.overflowY = 'visible'; // Робимо прокрутку доступною
  sessionStorage.setItem('visited', 'true'); // Зберігаємо інформацію про відвідування у sessionStorage
}

// Показуємо прелоадер при переході на іншу сторінку
document.querySelectorAll('a').forEach(link => {
  link.addEventListener('click', function(event) {
    event.preventDefault();
    const href = this.href;
    document.getElementById('loaderContainer').classList.remove('preloader-hidden'); // Приховуємо прелоадер

    setTimeout(() => {
      window.location.href = href;
    }, 1000); // Затримка для показу прелоадера
  });
});
