import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type/lib/SplitType";

gsap.registerPlugin(ScrollTrigger);

const textLines = document.querySelectorAll(".sk-background-text h2, .sk-background-text p");
textLines.forEach(line => {
  line.classList.add('split-lines');
});

let typeSplit;

function runSplit() {
  typeSplit = new SplitType(".split-lines", {
    types: "lines, words",
  });

  document.querySelectorAll('.line').forEach(line => {
    if (line.querySelector('strong')) {
      line.classList.add('sk-typo-mod-font-700');
    }
  });

  createAnimation();
}

if (window.innerWidth > 1025) {
  runSplit();
}

let windowWidth = window.innerWidth;
window.addEventListener("resize", function () {
  if (windowWidth !== window.innerWidth) {
    windowWidth = window.innerWidth;
      typeSplit.revert();
      runSplit();
  }
});

function createAnimation() {
  document.querySelectorAll(".line").forEach((line) => {
    gsap.to(line, {
      backgroundPositionX: 0,
      ease: "none",
      duration: 13,
      scrollTrigger: {
        trigger: line,
        scrub: 2,
        start: "top center",
        end: "bottom center",
      },
    });
  });
}

// animation-text-slide

document.addEventListener('DOMContentLoaded', () => {
  if (window.innerWidth > 1024) {
    const slideLines = document.querySelectorAll('.slide-lines');

    const slideSplit = new SplitType(slideLines, {
      types: "lines, words",
      lineClass: "slide-line",
      wordClass: "slide-word",
    });

    const tl = gsap.timeline();

    const additionalWords = document.querySelectorAll('.slide-word');

    slideSplit.words.forEach((word) => {
      gsap.set(word, {top: '1.3em'});
    });

    const allWords = [...slideSplit.words, ...additionalWords];

    allWords.forEach((word, index) => {
      tl.to(word, {
        duration: 0.5,
        top: '0',
        ease: "power4.out",
      },  index * 0.1);
    });

    tl.add(() => {
      slideLines.forEach(line => {
        line.classList.add('text-loaded');
      });
    });
  }
});

document.addEventListener('DOMContentLoaded', () => {
  if (window.innerWidth > 1024) {
    const slideLines = document.querySelectorAll('.services-slide-lines');

    const slideSplit = new SplitType(slideLines, {
      types: "lines",
      lineClass: "services-slide-line",
    });

    slideSplit.lines.forEach(line => {
      const wrapperDiv = document.createElement('div');
      wrapperDiv.classList.add('slide-line-wrapper');
      line.parentNode.insertBefore(wrapperDiv, line);
      wrapperDiv.appendChild(line);
    });

    const tl = gsap.timeline();

    slideSplit.lines.forEach((line) => {
      gsap.set(line, { top: '1.3em' });
    });

    slideSplit.lines.forEach((line, index) => {
      tl.to(line, {
        duration: 1,
        top: '0',
        ease: "power4.out",
        onComplete: () => {
          slideLines.forEach(slideLine => slideLine.classList.add('text-loaded'));
        },
      }, 0.5 + index * 0.15);
    });
  }
});


