import './modules/blurred-circle';
import './modules/contact-button';
import './modules/header';
import './modules/footer';
import './modules/video';
import './modules/preloader';
import './modules/text';
import './modules/canvas';
import '../../blocks/running-text/running-text'
import AOS from "aos";

window.addEventListener('load', function() {
    let resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);

    setTimeout(function() {
      AOS.init({disable: 'mobile'});
      AOS.refresh();
    }, 100);
});

function checkScreenWidth() {
  const canvas = document.getElementById('canvas');
  if (window.innerWidth < 1024) {
    if (canvas) {
      canvas.remove();
    }
  }
}

window.onload = checkScreenWidth;

window.onresize = checkScreenWidth;
