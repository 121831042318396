import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";


gsap.registerPlugin(ScrollTrigger, ScrollSmoother);


let lastScrollTop = 0;

window.addEventListener('scroll', function() {
  let header = document.getElementById('header');

  // Перевірка на наявність класу 'menu-open'
  if (header.classList.contains('menu-open')) {
    return; // Якщо клас 'menu-open' присутній, припиняємо виконання
  }

  let currentScroll = window.pageYOffset || document.documentElement.scrollTop;

  let heroAboutSection = document.querySelector('.hero-about');
  let isHeroAboutFullyScrolled = heroAboutSection ? (heroAboutSection.getBoundingClientRect().bottom <= window.innerHeight) : false;

  // Виправлення для видалення класу при скролі до самого верху
  if (currentScroll <= 0) {
    header.classList.remove('scrolled-up');
    header.classList.remove('scrolled-down');
    lastScrollTop = currentScroll; // Оновлюємо lastScrollTop при скролі до верху
    return; // Завершуємо обробку події, якщо скрол на самому верху
  }

  if (heroAboutSection) {
    if (currentScroll > 0 && currentScroll < lastScrollTop && isHeroAboutFullyScrolled) {
      header.classList.add('scrolled-up');
      header.classList.remove('scrolled-down');
    } else {
      header.classList.remove('scrolled-up');
      if (isHeroAboutFullyScrolled) {
        header.classList.toggle('scrolled-down', currentScroll > lastScrollTop);
      }
    }
  } else {
    if (currentScroll > lastScrollTop) {
      header.classList.remove('scrolled-up');
      header.classList.add('scrolled-down');
    } else {
      header.classList.add('scrolled-up');
      header.classList.remove('scrolled-down');
    }
  }

  lastScrollTop = currentScroll;
}, false);

document.addEventListener('DOMContentLoaded', () => {
  if (window.innerWidth > 1024) {

    ScrollSmoother.create({
      wrapper: "#smooth-wrapper",
      content: "#smooth-content",
      smooth: 1,
      normalizeScroll: true,
      ignoreMobileResize: true,

      effects: true,
    });
  }
});


document.getElementById('mobile-menu-toggle').addEventListener('click', function() {
  document.body.classList.toggle('menu-open');
  document.querySelector('header').classList.toggle('menu-open');
});
