import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

window.addEventListener('load', () => {
  gsap.registerPlugin(ScrollTrigger);

  const roll1 = createRoll(".footer__bottom", { duration: 15 });

  ScrollTrigger.create({
    onUpdate(self) {
      const direction = self.getVelocity() < 0 ? -1 : 1;
      gsap.to([roll1.ticker], { timeScale: direction, overwrite: true });
    },
    repeatRefresh: true,
  });

  function createRoll(targets, vars) {
    vars = vars || {};
    vars.ease || (vars.ease = "none");
    const tl = gsap.timeline({
      repeat: -1,
      paused: true,
    });
    const elements = gsap.utils.toArray(targets);
    elements.forEach(el => {
      tl.to(el, { xPercent: 100, ...vars }, 0);
    });
    tl.play();
    return { ticker: tl, elements };
  }

});
