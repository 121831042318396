import { gsap } from 'gsap';

window.addEventListener('load', () => {
  const container = document.querySelector(".cursors-wrapper");
  const circle = document.querySelector(".custom-cursors");
  const hoverWrappers = document.querySelectorAll('[data-hover-wrapper]');
  const blurredCircle = document.querySelector('.blurred-circle');
  const noCursor = document.querySelectorAll('.no-cursors');

  gsap.set(circle, { scale: 0, xPercent: -50, yPercent: -50 });

  hoverWrappers.forEach(wrapper => {
    const hoverType = wrapper.getAttribute('data-hover-wrapper');
    const hoverElement = document.querySelector(`[data-hover="${hoverType}"]`);

    wrapper.addEventListener('pointerenter', () => {
      gsap.to(blurredCircle, {
        scale: 0,
        opacity: 0,
        duration: 0.3,
      });
      gsap.to(hoverElement, {
        scale: 1,
        opacity: 1,
        duration: 0.3,
      });
    });

    wrapper.addEventListener('pointerleave', () => {
      gsap.to(blurredCircle, {
        scale: 1,
        opacity: 1,
        duration: 0.3,
      });
      gsap.to(hoverElement, {
        scale: 0,
        opacity: 0,
        duration: 0.3,
      });
    });
  });

  if(window.innerWidth > 1024) {

    container.addEventListener("pointerenter", (e) => {
      gsap.to(circle, { scale: 1, opacity: 1, duration: 0.3 });
      positionCircle(e);
    });

    container.addEventListener("pointerleave", (e) => {
      gsap.to(circle, { scale: 0, opacity: 0, duration: 0.3 });
      positionCircle(e);
    });

    container.addEventListener("pointermove", (e) => {
      positionCircle(e);
    });

  }

  function positionCircle(e) {
    const containerRect = container.getBoundingClientRect();
    const relX = e.clientX - containerRect.left - window.pageXOffset;
    const relY = e.clientY - containerRect.top - window.pageYOffset;

    gsap.to(circle, { x: relX, y: relY, duration: 0.6 });
  }

  noCursor.forEach(cursor => {
    cursor.addEventListener('mouseover', function() {
      circle.style.display = 'none';
    });

    cursor.addEventListener('mouseout', function() {
      circle.style.display = '';
    });
  })


});

if (window.innerWidth > 1024) {
  document.querySelectorAll('.magnetic-wrapper').forEach(function(container) {
    let contactButton = container.querySelector('.sk-contact-button');

    container.addEventListener('mouseleave', function(){
      gsap.to(this, {duration: 0.15});
      gsap.to(contactButton, {duration: 0.15, x: 0, y: 0});
    });

    container.addEventListener('mouseenter', function(){
      gsap.to(this, {duration: 0.15, transformOrigin: '0 0'});
      gsap.to(contactButton, {duration: 0.15});
    });

    container.addEventListener('mousemove', function(e){
      callParallax(e, this, contactButton);
    });
  });

}


function callParallax(e, container, contactButton){
  parallaxIt(e, container, contactButton, 80);
}

function parallaxIt(e, container, target, movement){
  let targetRect = container.getBoundingClientRect();

  let relX = e.clientX - targetRect.left;
  let relY = e.clientY - targetRect.top;

  gsap.to(target, {
    duration: 0.15,
    x: (relX - targetRect.width/2) / targetRect.width * movement,
    y: (relY - targetRect.height/2) / targetRect.height * movement,
    ease: 'none',
  });

  gsap.to(container, { // Додано анімацію для контейнера з однаковою тривалістю
    duration: 0.15,
    ease: 'none',
  });
}
